import React from 'react'
import Products from '../components/Products';
import { Container, Col, Row } from 'react-bootstrap/';
import Slider from '../components/Slider'
import AboutUs from '../assets/images/about-us.png'
import Team from '../assets/images/team.png'
import Standard1 from '../assets/images/standards/pet.png'
import Standard2 from '../assets/images/standards/pe-hd.png'
import Standard3 from '../assets/images/standards/pvc.png'
import Standard4 from '../assets/images/standards/pe-ld.png'
import Standard5 from '../assets/images/standards/pp.png'
import Standard6 from '../assets/images/standards/ps.png'
import Standard7 from '../assets/images/standards/o.png'
import ContactForm from '../components/ContactForm';
import RoomIcon from '@mui/icons-material/Room';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Suppliers from '../components/Suppliers'
import GalleryImages from '../components/Gallery'

const Homepage = () => {
    return (
        <div>
            <Slider />
            <section id="about">
                <Container>
                    <Row>
                        <Col md={6}>
                            <span className="subheading">About Us</span>
                            <h2>About Us</h2>
                            <div className="d-block d-sm-none">
                                <img src={AboutUs} alt="about" className="img-fluid" />
                                <div className="experience">
                                    <span>21</span><h4 className="my-auto"> Years of <br /> Industrial <br /> Experience</h4>
                                </div>
                            </div>
                            <p>Mepani Exim is a Global distribution & Trading company involved in plastic row materials. We deal in all kinds of prime, Near Prime, Off Grade & recycle granules. We also deal in plastic regrinds and lumps & scraps.</p>
                            <p>We currently buy from USA, South American Countries, Canada, Australia, New Zealand, African Countries, Far East Countries and sell polymers to Indian Sub-Continent, African, European & CIS Countries.</p>
                            <p>Mepani has become a brand-name in the polymer trading industry. Mepani. now stands for quality, trust, authenticity, integrity and high moral values in polymer trading.</p>
                        </Col>
                        <Col md={6} className="d-none d-sm-block text-center">
                            <img src={AboutUs} alt="about" className="img-fluid" />
                            <div className="experience">
                                <span>21</span><h4 className="my-auto"> Years of <br /> Industrial <br /> Experience</h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="standards">
                <Container>
                    <Row>
                        <Col xs={6} md>
                            <div className="standards type-1">
                                <img src={Standard1} alt="std" className="mt-3" />
                                <h4>PET</h4>
                                <div className="bottom">
                                    <h5>Polyethylene<br /> Terephthalate</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} md>
                            <div className="standards type-2">
                                <img src={Standard2} alt="std" className="mt-3" />
                                <h4>PE-HD</h4>
                                <div className="bottom">
                                    <h5>Polyethylene<br />(High Density)</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} md>
                            <div className="standards type-3">
                                <img src={Standard3} alt="std" className="mt-3" />
                                <h4>PVC</h4>
                                <div className="bottom">
                                    <h5>Polyvinyl<br /> Chloride</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} md>
                            <div className="standards type-4">
                                <img src={Standard4} alt="std" className="mt-3" />
                                <h4>PE-LD</h4>
                                <div className="bottom">
                                    <h5>Polyethylene<br />(Low Density)</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} md>
                            <div className="standards type-5">
                                <img src={Standard5} alt="std" className="mt-3" />
                                <h4>PP</h4>
                                <div className="bottom ">
                                    <h5>Polypropylene</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} md>
                            <div className="standards type-6">
                                <img src={Standard6} alt="std" className="mt-3" />
                                <h4>PS</h4>
                                <div className="bottom">
                                    <h5>Polyestyrene</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} md>
                            <div className="standards type-7">
                                <img src={Standard7} alt="std" className="mt-3" />
                                <h4>O</h4>
                                <div className="bottom">
                                    <h5>Bisphenol A <br />and Others</h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Products />
            <section id="enquiry">
                <Container>
                    <Row>
                        <Col md={9} className="my-auto">
                            <h2>Have A Question In Mind ?</h2>
                            <p>If you have any question regarding  Polymer or Any enquiry press Enquiry now</p>
                        </Col>
                        <Col md={3} className="text-center my-auto">
                            <a href="#contact" className="btn-type-2">Enquiry Now</a>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Suppliers />
            <section className="section-bg">
                <Container>
                    <Row>
                        <Col md={5} className="d-none d-sm-block">
                            <img src={Team} alt="Team" className="img-fluid" />
                        </Col>
                        <Col md={6} className="team my-auto">
                            <span className="subheading">OUR TEAM</span>
                            <h2>We’re a Results Driven Team</h2>
                            <img src={Team} alt="Team" className="img-fluid d-block d-sm-none" />
                            <p>Our team of professionals comprises qualified and experienced traders, consultants, managers, quality control managers, marketers, technicians, who are quite capable of effective planning and execution in accordance with the desired standard and given specifications.</p>
                            <p>The company trades in all types of polymer raw materials such as prime, Off-grade, floor sweeping, reprocessed and plant wastes etc. Mepani is providing a one window solution to all the plastic product manufacturers.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <GalleryImages />
            <section id="contact" className="section-bg">
                <Container>
                    <div className="text-center">
                        <span className="subheading">CONTACT US</span>
                        <h2 className="type-2">How Can We Help You ?</h2>
                    </div>
                    <Row className="justify-content-center">
                        <Col md={7}>
                            <ContactForm />
                        </Col>
                        <Col md={4} xs={12} className="contact-info my-auto">
                            <RoomIcon /> <p><b>Address</b><br /> Gram Laxmi Society, <br /> Junavas Madhapar, <br /> Bhuj Kutch, <br /> Gujarat 370001</p>
                            <hr />
                            <MailOutlineIcon /> <p><b>Email</b><br /><a href="mailto:balram@mepaniexim.com">balram@mepaniexim.com</a><br /></p>
                            <hr />
                            <LocalPhoneIcon /> <p className='margin-0'><b>Phone</b><br /><a href="tel:+91 98797 66289">+91 98797 66289</a></p>
                            <p><a href="tel:+91 99040 57417">+91 99040 57417</a></p>
                            <hr />
                            {/* eslint-disable-next-line */}
                            <WhatsAppIcon /> <p><b>WhatsApp</b><br /><a href="https://wa.me/919879766289?text=I%20am%20interested%20in%20your%20service%20" target="_blank">+91 98797 66289</a></p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Homepage
