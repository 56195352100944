import RecycleGranules from './assets/images/products/recycle-granules.jpg'
import HdpeBlue from './assets/images/products/hdpe-blue.jpg'
import Hdpe100 from './assets/images/products/hdpe-100.jpg'
import PvcRegrinds from './assets/images/products/pvc-regrinds.jpg'
import HdpeRegrinds from './assets/images/products/hdpe-regrinds.jpg'
import PpcpWhite from './assets/images/products/ppcp-white.jpg'

import BoppRolls from './assets/images/products/bopp-rolls.jpg'
import PePpLumps from './assets/images/products/pe-pp-lumps.jpg'
import LdpeFilmBales from './assets/images/products/ldpe-film-bales.jpg'
import HdpeDrumBalesHdpeCans from './assets/images/products/hdpe-drum-bales-hdpe-cans.jpg'
import HdpePipes from './assets/images/products/hdpe-pipes.jpg'
import PetBottleBales from './assets/images/products/pet-bottle-bales.jpg'

import PrimeEVA from './assets/images/products/prime-eva.jpg'
import OffgradeLDPE from './assets/images/products/offgrade-ldpe.jpg'
import FloorSweeping from './assets/images/products/floor-sweeping.jpg'
import HdpeDrumBalesHdpeCans2 from './assets/images/products/hdpe-drum-bales-hdpe-cans-2.jpg'
import PPCP from './assets/images/products/ppcp.jpg'
import PetBottleBales2 from './assets/images/products/pet-bottle-bales-2.jpg'

export const recycleGranuleAndRegrinds = [
    {
        name: "Recycle Granules",
        image: RecycleGranules,
        imageAlt: "Recycle Granules",
    },
    {
        name: "Hdpe Blue",
        image: HdpeBlue,
        imageAlt: "Hdpe Blue",
    },
    {
        name: "Hdpe 100",
        image: Hdpe100,
        imageAlt: "Hdpe 100",
    },
    {
        name: "Pvc Regrinds",
        image: PvcRegrinds,
        imageAlt: "Pvc Regrinds",
    },
    {
        name: "Hdpe Regrinds",
        image: HdpeRegrinds,
        imageAlt: "Hdpe Regrinds",
    },
    {
        name: "Ppcp White",
        image: PpcpWhite,
        imageAlt: "Ppcp White",
    },
]

export const plasticScrapsAndLumps = [
    {
        name: "Bopp Rolls",
        image: BoppRolls,
        imageAlt: "Bopp Rolls",
    },
    {
        name: "HDPE Pipes",
        image: PePpLumps,
        imageAlt: "HDPE Pipes",
    },
    {
        name: "Ldpe Film Bales",
        image: LdpeFilmBales,
        imageAlt: "Ldpe Film Bales",
    },
    {
        name: "PP Bales",
        image: HdpeDrumBalesHdpeCans,
        imageAlt: "PP Bales",
    },
    {
        name: "Pet Bales",
        image: HdpePipes,
        imageAlt: "Pet Bales",
    },
    {
        name: "Pet Preform",
        image: PetBottleBales,
        imageAlt: "Pet Preform",
    },
]

export const primeNearPrimeOffGrade = [
    {
        name: "Prime EVA",
        image: PrimeEVA,
        imageAlt: "Prime EVA",
    },
    {
        name: "Offgrade LDPE",
        image: OffgradeLDPE,
        imageAlt: "Offgrade LDPE",
    },
    {
        name: "Floor Sweeping",
        image: FloorSweeping,
        imageAlt: "Floor Sweeping",
    },
    {
        name: "Off grade in Jumbo bags",
        image: HdpeDrumBalesHdpeCans2,
        imageAlt: "Off grade in Jumbo bags",
    },
    {
        name: "PPCP",
        image: PPCP,
        imageAlt: "PPCP",
    },
    {
        name: "PVC off grade",
        image: PetBottleBales2,
        imageAlt: "PVC off grade",
    },
]
