import React, { useState } from 'react'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Logo from '../assets/images/logo/logo.png'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Divider, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
    const [sideMenu, setSideMenu] = useState(false)
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setSideMenu({ sideMenu, 'left': open });
    };
    return (
        <div>
            <div id="header-top" className="d-none d-sm-block">
                <Container>
                    <Row>
                        <Col md={6} className="d-flex">
                            <LocalPhoneIcon /><p>Call Us <br /><a href="tel:+91 98797 66289">+91 98797 66289</a><br /><a href="tel:+91 99040 57417">+91 99040 57417</a></p>
                            <MailOutlineIcon className="ms-5" /><p>Email Us <br /><a href="mailto:balram@mepaniexim.com">balram@mepaniexim.com</a></p>
                        </Col>
                        <Col md={6} className="my-auto text-end">
                            <a href="#contact" className="btn-type-2">Get Free Quote</a>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="header">
                <Container>
                    {/* Mobile Menu */}
                    <SwipeableDrawer
                        open={sideMenu['left']}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                    >
                        <Box
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                        >
                            <img src={Logo} alt="Logo" className="img-fluid p-3 bg-primary" />
                            <Divider />
                            <List>
                                <a href="./">
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                                        </ListItemIcon>
                                        <ListItemText primary="Home" />
                                    </ListItem>
                                </a>
                                <a href="./#about">
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                                        </ListItemIcon>
                                        <ListItemText primary="About Us" />
                                    </ListItem>
                                </a>
                                <a href="./#products">
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                                        </ListItemIcon>
                                        <ListItemText primary="Products" />
                                    </ListItem>
                                </a>
                                <a href="./#gallery">
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                                        </ListItemIcon>
                                        <ListItemText primary="Gallery" />
                                    </ListItem>
                                </a>
                                <a href="./#contact">
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                                        </ListItemIcon>
                                        <ListItemText primary="Contact Us" />
                                    </ListItem>
                                </a>
                            </List>
                        </Box>
                    </SwipeableDrawer>
                    {/* END: Mobile Menu */}
                    <Link to="/">
                        <img src={Logo} alt="Logo" />
                    </Link>
                    <Button onClick={toggleDrawer(true)} className="d-block d-sm-none menuicon"><MenuIcon /></Button>
                    <nav id="navbar" className="navbar d-none d-sm-block">
                        <ul>
                            <li><a href="./">Home</a></li>
                            <li><a href="./#about">About Us</a></li>
                            <li><a href="./#products">Products</a></li>
                            <li><a href="./#gallery">Gallery</a></li>
                            <li><a href="./#contact">Contact Us</a></li>
                        </ul>
                    </nav>
                </Container>
            </div>
        </div>
    )
}

export default Header
