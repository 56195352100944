import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { recycleGranuleAndRegrinds, plasticScrapsAndLumps, primeNearPrimeOffGrade } from '../products'
import { Container, Col, Row } from 'react-bootstrap/';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, Typography } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Products = () => {
    const [open, setOpen] = useState(false);
    const [fullImage, setFullImage] = useState('')
    const [fullImageAlt, setFullImageAlt] = useState('')

    const showFullImage = (image, imageAlt) => {
        setFullImage(image)
        setFullImageAlt(imageAlt)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    return (
        <section id="products">
            <Container className="text-center">
                <span className="subheading">Our Products</span>
                <h2 className="type-2">Our Polymer Products Includes</h2>
                <div className="d-none d-sm-block">
                    <Box>
                        <Tabs value={value} onChange={handleChange} style={{ borderBottom: 'unset' }} variant="scrollable" centered>
                            <Tab label="Reycled Granules and Regrind" {...a11yProps(0)} />
                            <Tab label="Plastic Scraps and Lumps" {...a11yProps(1)} />
                            <Tab label="Prime, Near Prime, Off Grade" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Row>
                            {recycleGranuleAndRegrinds.map((product, index) => {
                                return (
                                    <Col md={6} key={index}>
                                        <div className="product">
                                            <img src={product.image} alt={product.imageAlt} className="img-fluid" onClick={() => showFullImage(product.image, product.imageAlt)} />
                                            <div className="info">
                                                <h5>{product.name}</h5>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Row>
                            {plasticScrapsAndLumps.map((product, index) => {
                                return (
                                    <Col md={6} key={index}>
                                        <div className="product">
                                            <img src={product.image} alt={product.imageAlt} className="img-fluid" onClick={() => showFullImage(product.image, product.imageAlt)} />
                                            <div className="info">
                                                <h5>{product.name}</h5>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Row>
                            {primeNearPrimeOffGrade.map((product, index) => {
                                return (
                                    <Col md={6} key={index}>
                                        <div className="product">
                                            <img src={product.image} alt={product.imageAlt} className="img-fluid" onClick={() => showFullImage(product.image, product.imageAlt)} />
                                            <div className="info">
                                                <h5>{product.name}</h5>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </TabPanel>
                </div>
                <div className="d-block d-sm-none">
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Reycled Granules and Regrind</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <Row>
                                    {recycleGranuleAndRegrinds.map((product, index) => {
                                        return (
                                            <Col md={6} xs={6} key={index}>
                                                <div className="product">
                                                    <img src={product.image} alt={product.imageAlt} className="img-fluid" onClick={() => showFullImage(product.image, product.imageAlt)} />
                                                    <div className="info">
                                                        <h5>{product.name}</h5>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Plastic Scraps and Lumps</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <Row>
                                    {plasticScrapsAndLumps.map((product, index) => {
                                        return (
                                            <Col md={6} xs={6} key={index}>
                                                <div className="product">
                                                    <img src={product.image} alt={product.imageAlt} className="img-fluid" onClick={() => showFullImage(product.image, product.imageAlt)} />
                                                    <div className="info">
                                                        <h5>{product.name}</h5>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography>Prime, Near Prime, Off Grade</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <Row>
                                    {primeNearPrimeOffGrade.map((product, index) => {
                                        return (
                                            <Col md={6} xs={6} key={index}>
                                                <div className="product">
                                                    <img src={product.image} alt={product.imageAlt} className="img-fluid" onClick={() => showFullImage(product.image, product.imageAlt)} />
                                                    <div className="info">
                                                        <h5>{product.name}</h5>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <img src={fullImage} alt={fullImageAlt} className="img-fluid" />
                </Dialog>
            </Container>
        </section>
    )
}

export default Products
