import React from 'react'
import Gallery from 'react-grid-gallery'
import { Container, Col, Row } from 'react-bootstrap/';
import GalleryImg1 from '../assets/images/gallery/gallery-image-1.jpg'
import GalleryImg1Full from '../assets/images/gallery/gallery-image-1-full.jpg'
import GalleryImg2 from '../assets/images/gallery/gallery-image-2.jpg'
import GalleryImg2Full from '../assets/images/gallery/gallery-image-2-full.jpg'
import GalleryImg3 from '../assets/images/gallery/gallery-image-3.jpg'
import GalleryImg3Full from '../assets/images/gallery/gallery-image-3-full.jpg'
import GalleryImg4 from '../assets/images/gallery/gallery-image-4.jpg'
import GalleryImg4Full from '../assets/images/gallery/gallery-image-4-full.jpg'
import GalleryImg5 from '../assets/images/gallery/gallery-image-5.jpg'
import GalleryImg5Full from '../assets/images/gallery/gallery-image-5-full.jpg'
import GalleryImg6 from '../assets/images/gallery/gallery-image-6.jpg'
import GalleryImg6Full from '../assets/images/gallery/gallery-image-6-full.jpg'
import GalleryImg7 from '../assets/images/gallery/gallery-image-7.jpg'
import GalleryImg7Full from '../assets/images/gallery/gallery-image-7-full.jpg'
import GalleryImg8 from '../assets/images/gallery/gallery-image-8.jpg'
import GalleryImg8Full from '../assets/images/gallery/gallery-image-8-full.jpg'
import GalleryImg9 from '../assets/images/gallery/gallery-image-9.jpg'
import GalleryImg9Full from '../assets/images/gallery/gallery-image-9-full.jpg'
import GalleryImg10 from '../assets/images/gallery/gallery-image-10.jpg'
import GalleryImg10Full from '../assets/images/gallery/gallery-image-10-full.jpg'
import GalleryImg11 from '../assets/images/gallery/gallery-image-11.jpg'
import GalleryImg11Full from '../assets/images/gallery/gallery-image-11-full.jpg'
import GalleryImg12 from '../assets/images/gallery/gallery-image-12.jpg'
import GalleryImg12Full from '../assets/images/gallery/gallery-image-12-full.jpg'

const IMAGES = [
    {
        src: GalleryImg1Full,
        thumbnail: GalleryImg1,
    },
    {
        src: GalleryImg2Full,
        thumbnail: GalleryImg2,
    },
    {
        src: GalleryImg3Full,
        thumbnail: GalleryImg3,
    },
    {
        src: GalleryImg4Full,
        thumbnail: GalleryImg4,
    },
    {
        src: GalleryImg5Full,
        thumbnail: GalleryImg5,
    },
    {
        src: GalleryImg6Full,
        thumbnail: GalleryImg6,
    },
    {
        src: GalleryImg7Full,
        thumbnail: GalleryImg7,
    },
    {
        src: GalleryImg8Full,
        thumbnail: GalleryImg8,
    },
    {
        src: GalleryImg9Full,
        thumbnail: GalleryImg9,
    },
    {
        src: GalleryImg10Full,
        thumbnail: GalleryImg10,
    },
    {
        src: GalleryImg11Full,
        thumbnail: GalleryImg11,
    },
    {
        src: GalleryImg12Full,
        thumbnail: GalleryImg12,
    }
]

const GalleryImages = () => {
    return (
        <section id="gallery">
            <Container>
                <div className="text-center">
                    <span className="subheading">Gallery</span>
                    <h2 className="type-2">Have a look at our Products</h2>
                </div>
                <Row className="justify-content-md-center mt-4">
                    <Col md={10}>
                        <Gallery images={IMAGES} enableImageSelection={false} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default GalleryImages
