import React from 'react'
import { Container } from 'react-bootstrap';
// Library
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderHomepage = () => {
    var settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
    };
    return (
        <div id="slider">
            <Slider {...settings}>
                <div>
                    <div className="content slider-1">
                        <Container>
                            <h1>Real Partner and <span>Supplier of Polymer</span>  since 2000</h1>
                        </Container>
                    </div>
                </div>
                <div>
                    <div className="content slider-2">
                        <Container>
                            <h2>We Deliver All Type of <span>Good Quality</span> Polymer</h2>
                        </Container>
                    </div>
                </div>
                <div>
                    <div className="content slider-3">
                        <Container>
                            <h2>We Deliver and <span>Commit</span></h2>
                        </Container>
                    </div>
                </div>
                <div>
                    <div className="content slider-4">
                        <Container>
                            <h2>We <span>Save Environment</span> by Recycling</h2>
                        </Container>
                    </div>
                </div>
                <div>
                    <div className="content slider-5">
                        <Container>
                            <h2>We <span>Don’t Allow</span> Plastic to be Disposed on Ocean</h2>
                        </Container>
                    </div>
                </div>
                <div>
                    <div className="content slider-6">
                        <Container>
                            <h2>A <span>Multinational Polymer</span> Trading Companny</h2>
                        </Container>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default SliderHomepage