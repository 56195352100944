import React from 'react'
import RoomIcon from '@mui/icons-material/Room';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Logo from '../assets/images/logo/logo.png'
import WhatsApp from '../assets/images/whatsapp.png'
import { Col, Container, Row } from 'react-bootstrap'

const Footer = () => {
    return (
        <div id="footer">
            <section>
                <Container>
                    <Row>
                        <Col md={4} xs={12}>
                            <img src={Logo} alt="Logo" />
                            <p>Mepani Exim Is A Global Distribution & Trading Compnay Involved In Plastic Row Materials. We Deal In All Kinds Of Prime, Near Prime, Off Grade & Recycle Granules. We Also Deal In Plastic Regrinds And Lumps & Scraps.</p>
                        </Col>
                        <Col md={2} xs={6}>
                            <h5>Quick Links</h5>
                            <ul>
                                <li><h6><a href="./">Home</a></h6></li>
                                <li><h6><a href="./#about">About Us</a></h6></li>
                            </ul>
                        </Col>
                        <Col md={3} xs={6}>
                            <h5>Useful Links</h5>
                            <ul>
                                <li><h6><a href="./#products">Products</a></h6></li>
                                <li><h6><a href="./#contact">Contact Us</a></h6></li>
                            </ul>
                        </Col>
                        <Col md={3} xs={12}>
                            <h5>Contact Us</h5>
                            <div className="contact">
                                <RoomIcon /> <p>Gram Laxmi Society, <br /> Junavas, Madhapar, <br /> Bhuj Kutch, <br /> Gujarat 370001</p>
                                <MailOutlineIcon /> <a href="mailto:balram@mepaniexim.com">balram@mepaniexim.com</a><br />
                                <LocalPhoneIcon /> <a href="tel:+91 98797 66289">+91 98797 66289</a><br />
                                <LocalPhoneIcon /> <a href="tel:+91 99040 57417">+91 99040 57417</a>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <div className="copyright">
                        {/* eslint-disable-next-line */}
                        <p>&copy; {new Date().getFullYear()} Mepaniexim. All rights reserved. Website designed and developed by Arkay Apps</p>
                    </div>
                </Container>
            </section>
            <div className="whatsapp">
                {/* eslint-disable-next-line */}
                <a href="https://wa.me/919879766289?text=I%20am%20interested%20in%20your%20service%20" target="_blank"><img src={WhatsApp} alt="WhatsApp Message" /></a>
            </div>
        </div>
    )
}

export default Footer
