import React, { useState } from 'react'
import { Alert, Col, Row, Form } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'
import { EMAIL_API } from '../config'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ContactForm = () => {
    let history = useHistory();
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState(null)
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [mobile, setMobile] = useState(null)
    const [message, setMessage] = useState(null)

    const mailError = (<p>{error}</p>)

    const handleName = (e) => {
        const value = e.target.value
        setName(value)
        setError(null)
    }
    const handleEmail = (e) => {
        var value = e.target.value
        value = ltrim(rtrim(value))
        setEmail(value)
        if (!isEmail(value)) {
            setError(<Alert variant={'danger'}>Please enter a valid email</Alert>)
            setDisabled(true)
        }
        else {
            setError(null)
            setDisabled(false)
        }
    }
    const handleMobile = (value) => {
        setMobile(value)
        setError(null)
    }
    const handleMessage = (e) => {
        const value = e.target.value
        setMessage(value)
        setError(null)
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        if (name === null) {
            setError(<Alert variant={'danger'}>Name Required</Alert>)
        } else if (mobile === null) {
            setError(<Alert variant={'danger'}>Mobile Number Required</Alert>)
        } else if (message === null) {
            setError(<Alert variant={'danger'}>Message Required</Alert>)
        } else {
            setDisabled(true)
            const data = {
                name: ltrim(rtrim(name)),
                email: (email === null || email === '') ? '' : ltrim(rtrim(email)),
                number: ltrim(rtrim(mobile)),
                message: ltrim(rtrim(message))
            }
            const url = EMAIL_API + '/contact-us'
            axios.post(url, data).then(response => {
                document.getElementById('contact-us').reset()
                setDisabled(false)
                history.push('/thank-you')
            }).catch(error => {
                if (error.response) {
                    console.log(error.response)
                } else if (error.request) {
                    console.log(error.request)
                } else {
                    console.log(error);
                }
                setDisabled(false)
            })
        }
    }
    return (
        <div>
            <Form className="contact-form" id="contact-us" onSubmit={handleFormSubmit}>
                <h4>Drop Us a Line</h4>
                <Row>
                    <Col md={12}>
                        <TextField className="form-control mt-unset" label="Name" size="small" variant="outlined" required inputProps={{ minLength: 3, maxLength: 50 }} onChange={handleName} />
                    </Col>
                    <Col md={6}>
                        <TextField className="form-control" label="Email" size="small" variant="outlined" inputProps={{ minLength: 3, maxLength: 100 }} onChange={handleEmail} />
                    </Col>
                    <Col md={6}>
                        <PhoneInput country={'in'} onChange={handleMobile} inputProps={{ minLength: 3, maxLength: 18, required: true }} />
                    </Col>
                    <Col md={12}>
                        <TextField multiline rows={4} className="form-control" size="small" label="Message" variant="outlined" required inputProps={{ minLength: 3, maxLength: 200 }} onChange={handleMessage} />
                    </Col>
                    {mailError}
                    <Col md={12}>
                        <Button type="submit" className="w-100" disabled={disabled}>Submit</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ContactForm