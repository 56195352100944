import React from 'react'
import { Container } from 'react-bootstrap/';
import Supplier1 from '../assets/images/suppliers/aramco.png'
import Supplier2 from '../assets/images/suppliers/borough.png'
import Supplier3 from '../assets/images/suppliers/dow.png'
import Supplier4 from '../assets/images/suppliers/exxon.png'
import Supplier5 from '../assets/images/suppliers/gail.png'
import Supplier6 from '../assets/images/suppliers/lyondell.png'
import Supplier7 from '../assets/images/suppliers/reliance.png'
import Supplier8 from '../assets/images/suppliers/sabic.png'
import Supplier9 from '../assets/images/suppliers/sinopec.png'
import Supplier10 from '../assets/images/suppliers/sipchem.png'
import Supplier11 from '../assets/images/suppliers/sumitomo.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Suppliers = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };
    return (
        <section>
            <Container className="supplier">
                <div className="text-center">
                    <h2 className="type-2">Our Suppliers</h2>
                </div>
                <Slider {...settings}>
                    <img src={Supplier1} alt="Suppliers" className="img-fluid" />
                    <img src={Supplier2} alt="Suppliers" className="img-fluid" />
                    <img src={Supplier3} alt="Suppliers" className="img-fluid" />
                    <img src={Supplier4} alt="Suppliers" className="img-fluid" />
                    <img src={Supplier5} alt="Suppliers" className="img-fluid" />
                    <img src={Supplier6} alt="Suppliers" className="img-fluid" />
                    <img src={Supplier7} alt="Suppliers" className="img-fluid" />
                    <img src={Supplier8} alt="Suppliers" className="img-fluid" />
                    <img src={Supplier9} alt="Suppliers" className="img-fluid" />
                    <img src={Supplier10} alt="Suppliers" className="img-fluid" />
                    <img src={Supplier11} alt="Suppliers" className="img-fluid" />
                </Slider>
            </Container>
        </section>
    )
}

export default Suppliers
