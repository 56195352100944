// Core
import React from 'react'
// Library
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// Components
import Header from './components/Header'
import Footer from './components/Footer'
// Pages
import Homepage from './pages/Homepage'
import ThankYou from './pages/ThankYou'
import PrivacyPolicy from './pages/PrivacyPolicy'

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Homepage} />
          <Route exact path='/thank-you' component={ThankYou} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;